import { graphql } from "gatsby";
import React, { FC } from "react";
import BlogsMain from "../components/BlogUiComponents/BlogsMain/BlogsMain";
import { UseTagsFilter } from "../components/hooks/UseTagsFilter";
import Layout from "../components/Layout/Layout";
import { SEO } from "../components/seo";

type Props = {
  data: any;
  pageContext: any;
  location: any;
};

const CloudList: FC<Props> = ({ data, pageContext, location }) => {
  const { currentPage, cloudPaginationNumber, blogs } = pageContext;

  const selectedTags = UseTagsFilter(
    "cloud-insights",
    blogs,
    data.allSanityTag.nodes
  );

  return (
    <Layout location={"/blog"}>
      <SEO
        title="Cloud insights"
        description="Stay up to date with the latest cloud insights and all the related news."
        type="website"
      />
      <>
        <BlogsMain
          blogName={"/cloud-insights"}
          basePathForUrl={"/cloud-insights"}
          data={data.cloudBlogs.edges}
          tags={selectedTags}
          numberPages={cloudPaginationNumber}
          currentPage={currentPage}
        />
      </>
    </Layout>
  );
};

export default CloudList;

export const blogListQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    cloudBlogs: allSanityBlog(
      limit: $limit
      skip: $skip
      filter: { blogType: { elemMatch: { title: { eq: "cloud-insights" } } } }
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          id
          subtitle
          title
          blogType {
            title
          }
          _rawBody
          _rawMainImage(resolveReferences: { maxDepth: 20 })
          body {
            children {
              text
            }
          }
          tags {
            title
          }
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
          alt
          publishedAt
        }
      }
      pageInfo {
        totalCount
      }
    }
    allSanityTag {
      nodes {
        title
      }
    }
  }
`;
